import React from 'react';

const Footer = () => (
  <footer className="bg-black bg-opacity-50 py-6 mt-16">
    <div className="container mx-auto px-4 text-center">
      <p>&copy; 2024 TUGADOT. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;