import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Lightbulb, Shield, Users, Award, ChevronRight, Zap, Cpu } from 'lucide-react';
import { TypeAnimation } from 'react-type-animation';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const ValueCard = ({ icon: Icon, value }) => (
  <div className="flex items-center space-x-3 bg-purple-800 p-3 rounded-lg">
    <Icon className="text-purple-300" size={20} />
    <span className="text-sm font-semibold text-white">{value}</span>
  </div>
);

const IconWrapper = ({ icon: Icon }) => (
  <span className="inline-block mr-2 align-middle">
    <Icon size={20} className="text-purple-400" />
  </span>
);

const About = () => {
  const [showIcons, setShowIcons] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowIcons(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const paragraphs = [
    {
      icon: Cpu,
      text: "TUGADOT is a cutting-edge software agency at the forefront of technological innovation. Founded in 2024, we're on a mission to establish ourselves as leaders in AI integration and custom software development."
    },
    {
      icon: Zap,
      text: "Our goal is to empower businesses with transformative technology solutions that drive growth, efficiency, and innovation. We believe in the power of AI and emerging technologies to reshape industries and create new possibilities."
    },
    {
      icon: Users,
      text: "With a team of passionate technologists, data scientists, and creative problem-solvers, we're ready to tackle complex challenges and turn visionary ideas into reality. Our commitment to excellence and continuous learning ensures that we'll always stay ahead of the curve in the fast-paced world of technology."
    }
  ];

  return (
    <main className="container mx-auto px-4 py-12 sm:py-16 md:py-24">
      <motion.h2 
        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 text-center text-purple-300"
        {...fadeIn}
      >
        About TUGADOT
      </motion.h2>

      <motion.div 
        className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg mb-8 shadow-lg"
        {...fadeIn}
      >
        <h3 className="text-xl font-semibold mb-4 text-purple-300">Innovating the Future</h3>
        <div className="space-y-4">
          {paragraphs.map((paragraph, index) => (
            <div key={index} className="flex items-start">
              {showIcons && <IconWrapper icon={paragraph.icon} />}
              <TypeAnimation
                sequence={[paragraph.text, 1000]}
                wrapper="p"
                speed={50}
                style={{ fontSize: '16px', lineHeight: '1.5', color: '#E2E8F0' }}
                repeat={0}
                cursor={false}
              />
            </div>
          ))}
        </div>
      </motion.div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-8">
        <motion.div className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg text-center shadow-lg" {...fadeIn}>
          <h3 className="text-xl font-semibold mb-4 text-white">Our Vision</h3>
          <p className="text-gray-300">To be the catalyst for technological advancement, driving innovation across industries and improving lives through intelligent solutions.</p>
        </motion.div>

        <motion.div className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg shadow-lg" {...fadeIn} transition={{ delay: 0.2 }}>
          <h3 className="text-xl font-semibold mb-4 text-center text-white">Our Values</h3>
          <div className="grid grid-cols-2 gap-3">
            <ValueCard icon={Lightbulb} value="Innovation" />
            <ValueCard icon={Shield} value="Integrity" />
            <ValueCard icon={Users} value="Collaboration" />
            <ValueCard icon={Award} value="Excellence" />
          </div>
        </motion.div>

        <motion.div className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg text-center shadow-lg" {...fadeIn} transition={{ delay: 0.4 }}>
          <h3 className="text-xl font-semibold mb-4 text-white">Our Approach</h3>
          <p className="text-gray-300">We combine deep technical expertise with a human-centered design approach to deliver solutions that are not just powerful, but also intuitive and user-friendly.</p>
        </motion.div>
      </div>

      <motion.div 
        className="text-center"
        {...fadeIn}
        transition={{ delay: 0.6 }}
      >
        <h3 className="text-xl font-semibold mb-4 text-white">Ready to innovate with us?</h3>
        <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full transition-colors inline-flex items-center">
          Get in Touch
          <ChevronRight className="ml-2" size={16} />
        </button>
      </motion.div>
    </main>
  );
};

export default About;