import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to a server
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
    alert('Thanks for your message! We\'ll get back to you soon.');
  };

  return (
    <main className="container mx-auto px-4 py-24">
      <h2 className="text-4xl font-bold mb-8 text-center">Contact Us</h2>
      <div className="max-w-md mx-auto bg-white bg-opacity-10 p-8 rounded-lg">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-white bg-opacity-20 rounded" 
              required 
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-white bg-opacity-20 rounded" 
              required 
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mb-2">Message</label>
            <textarea 
              id="message" 
              name="message" 
              rows="4" 
              value={formData.message}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-white bg-opacity-20 rounded" 
              required
            ></textarea>
          </div>
          <button type="submit" className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full transition-colors w-full">
            Send Message
          </button>
        </form>
      </div>
    </main>
  );
};

export default Contact;