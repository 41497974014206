import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Cpu, Code, Zap, Briefcase } from 'lucide-react';

const ServiceCard = ({ title, description, items, icon: Icon, delay }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="bg-gradient-to-br from-purple-900 to-indigo-900 p-6 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center mb-4">
        <Icon className="text-purple-400 mr-3" size={32} />
        <h3 className="text-2xl font-semibold">{title}</h3>
      </div>
      <p className="mb-4 text-gray-300">{description}</p>
      <ul className="list-none space-y-2">
        {items.map((item, index) => (
          <motion.li
            key={index}
            className="flex items-center text-gray-200"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: isHovered ? 1 : 0, x: isHovered ? 0 : -20 }}
            transition={{ delay: index * 0.1 }}
          >
            <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
            {item}
          </motion.li>
        ))}
      </ul>
    </motion.div>
  );
};

const Services = () => {
  const services = [
    {
      title: "AI Integration",
      description: "Seamlessly integrate AI technologies into your existing systems or build new AI-powered solutions from the ground up.",
      items: ["Machine Learning Models", "Natural Language Processing", "Computer Vision Solutions", "Predictive Analytics"],
      icon: Cpu,
    },
    {
      title: "Custom Software Development",
      description: "Our team of expert developers creates bespoke software solutions tailored to your specific business needs.",
      items: ["Web Applications", "Mobile Apps (iOS & Android)", "Desktop Software", "APIs and Integrations"],
      icon: Code,
    },
    {
      title: "Rapid Prototyping",
      description: "Quickly bring your ideas to life with our rapid prototyping services, allowing for fast iteration and validation.",
      items: ["Concept Visualization", "MVP Development", "User Testing", "Iterative Design"],
      icon: Zap,
    },
    {
      title: "Consulting & Strategy",
      description: "Leverage our expertise to guide your technology decisions and digital transformation initiatives.",
      items: ["Technology Roadmapping", "AI Strategy Development", "Digital Transformation Consulting", "Tech Stack Optimization"],
      icon: Briefcase,
    },
  ];

  return (
    <main className="container mx-auto px-4 py-24">
      <motion.h2
        className="text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Our Services
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} delay={index * 0.2} />
        ))}
      </div>
    </main>
  );
};

export default Services;